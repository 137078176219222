import { useTheme } from "@mui/material/styles";
import * as React from "react";
// import { secondaryTextColor } from "../Theme/Theme";
export default function MyDivider({ color = "#fff3" }: { color?: string }) {
  const theme = useTheme();
  return (
    <div>
      <div style={{ height: 12 }}></div>
      <hr
        style={{
          borderColor: color,
          // borderColor: theme.palette.secondary.main,
          borderWidth: 2,
        }}
      ></hr>
      <div style={{ height: 12 }}></div>
    </div>
  );
}
