import {
  Card,
  CardContent,
  Theme,
  StyledEngineProvider,
  Typography,
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import * as React from "react";
import Link from "next/link";
import { RichText } from "prismic-reactjs";
// import { createTheme } from "@material-ui/core/styles";
import { useLanguageContext } from "../../i18n/LanguageContext";
import { BundleStoryData, PriceFloor } from "functions/src/shared";
import { LazyLoadImage } from "react-lazy-load-image-component";
import mixpanel from "mixpanel-browser";
import {
  BundleStoryblok,
  PriceStoryblok,
} from "functions/src/shared/Digital/StoryBlockSchema/components-schema";
import { StoryblokRichText } from "../../utils/Storyblok/RichText";
import document from "next/document";
import {
  discountBackground,
  discountColor,
  mainColor,
  secondaryTextColor,
} from "../../Theme/Theme";
import Row from "../../Utility/Row";
import { useCardStyle, lightCardTheme } from "./CardStyle";
import DiscountLabel from "./DiscountLabel";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export default function DigitalBundleCard({
  bundleData,
}: {
  bundleData: BundleStoryData;
}) {
  // const bundleData = bundle.data;
  const lngCtx = useLanguageContext();
  const bundle = bundleData.content;
  const priceData: PriceStoryblok =
    bundleData.content.price && bundleData.content.price[0]; //bundleData.price[];
  if (priceData == null) {
    return <div>plz fill in price</div>;
  }
  const price = priceData[lngCtx.currency];
  const discountPrice = price * (1 - priceData.discountPercent / 100);
  const cardStyleClass = useCardStyle();
  const label = lngCtx.priceLabel;
  return (
    <StyledEngineProvider injectFirst>
      {/* <ThemeProvider theme={lightCardTheme}> */}
      <Link href={"/bundles/" + bundleData.slug}>
        <a
          id={bundleData.slug + "_bundle"}
          style={{ textDecoration: "none" }}
          onClick={() => {
            mixpanel.track("click card", {
              type: "bundle",
              uid: bundleData.slug,
            });
          }}
        >
          <div
            className={cardStyleClass.root}
            style={{ width: 300, position: "relative" }}
          >
            <Card
              elevation={2}
              style={{
                // border: "solid 1px #333",
                borderRadius: 0,
                // backgroundColor: "#F2EEEB",
              }}
            >
              {/* <CardActionArea> */}
              {/* <LazyLoadImage
                  style={{ width: 300, height: 250 }}
                  src={bundleData.card_img.url}
                ></LazyLoadImage> */}

              <div style={{ marginBottom: 5 }}>
                <div
                  style={{
                    width: 300,
                    height: 250,
                    backgroundImage:
                      "url('" + bundle.card_img.filename + "/m/300x250" + "')",
                    margin: 0,
                    padding: 0,
                  }}
                >
                  <Typography
                    style={{
                      paddingLeft: 10,
                      paddingRight: 10,
                      background: discountBackground,
                      color: discountColor,
                      width: "auto",
                      display: "inline-block",
                      fontWeight: "bold",
                      boxShadow: "5px 5px 12px -5px rgba(0,0,0,0.66)",
                      fontSize: 18,
                    }}
                  >
                    {priceData.discountPercent &&
                      priceData.discountPercent > 0 &&
                      priceData.discountPercent + "% OFF"}
                  </Typography>
                </div>
              </div>

              <DiscountLabel priceData={priceData}></DiscountLabel>
              {/* <CardMedia
                  style={{ height: 250 }}
                  image={bundleData.card_img.url}
                ></CardMedia> */}
              {/* <div
                style={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Row
                  style={{
                    // width: 100,
                    paddingLeft: 16,
                    paddingRight: 16,
                    // paddingBottom: 8,
                    height: 38,
                    position: "absolute",
                    bottom: 5,
                    backgroundColor: "#000",
                  }}
                >
              
                  <Typography
                    style={{
                      textDecoration: "line-through",
                      fontSize: 14,
                      color: "#D9798D",
                      marginRight: 12,
                    }}
                  >
                    {label} {price}
                  </Typography>
                  <Typography style={{ fontSize: 20, color: "#fff" }}>
                    {label}
                    {PriceFloor(discountPrice, lngCtx.currency)}
                  </Typography>
                </Row>
              </div> */}

              <CardContent
                style={{ padding: 11, minHeight: 110, paddingBottom: 4 }}
              >
                <Typography
                  variant="h5"
                  style={{
                    fontSize: 20,
                    fontWeight: "bold",
                    fontFamily: "Raleway",
                  }}
                >
                  {bundle.title}
                </Typography>
                <div
                  style={{
                    // display: " -webkit-box",
                    // WebkitLineClamp: 2,
                    // WebkitBoxOrient: "vertical",
                    // overflow: "hidden",
                    // textOverflow: "ellipsis",
                    color: secondaryTextColor,
                    fontSize: 14,
                    paddingTop: 10,
                  }}
                >
                  <StoryblokRichText
                    document={bundle.contents}
                  ></StoryblokRichText>
                </div>
              </CardContent>
              {/* <CardActions style={{ padding: 16, color: secondaryTextColor }}>
               
                <FlexEmpty></FlexEmpty>
                <Typography
                  style={{ fontSize: 20, fontFamily: "ProximaNW01-Reg" }}
                  color="textPrimary"
                >
                  {price.label + " " + price.price}
                </Typography>
              </CardActions> */}
              {/* </CardActionArea> */}
            </Card>
            {/* <div className="overlay"></div> */}
          </div>
        </a>
      </Link>
      {/* </ThemeProvider> */}
    </StyledEngineProvider>
  );
}
