import { Container, Grid, Card, Paper, Typography } from "@mui/material";
import * as React from "react";
import DigitalGameCard from "./Cards/DigitalGameCard";
import MyDivider from "../Utility/Divider";
import DigitalBundleCard from "./Cards/DigitalBundleCard";
import { useTranslation } from "react-i18next";

import { BundleStoryData } from "functions/src/shared";
import { ProductStoryData } from "functions/src/shared";
import { useSmDown } from "../Nav/useDownSM";

export default function HomePage({
  bundles,
  games,
  osts,
}: {
  bundles?: BundleStoryData[];
  games?: ProductStoryData[];
  osts?: ProductStoryData[];
}) {
  const { t } = useTranslation("homepage");
  const isMobileOnly = useSmDown();
  return (
    <Container
      style={{
        paddingTop: isMobileOnly ? 24 : 66,
        paddingBottom: 48,
        maxWidth: 1000,
      }}
    >
      {/* <Card elevation={0}> */}
      <Typography
        gutterBottom
        // color="textPrimary"
        variant="h6"
        style={{ fontWeight: "bold", fontSize: 22, paddingBottom: 12 }}
      >
        {t("bundles")}
      </Typography>
      <Grid
        justifyContent={isMobileOnly ? "center" : "flex-start"}
        container
        spacing={3}
      >
        {bundles.map((bundle, index) => {
          return (
            <Grid key={index} item>
              <DigitalBundleCard bundleData={bundle}></DigitalBundleCard>
            </Grid>
          );
        })}
      </Grid>
      {/**-------------------------- */}{" "}
      <div style={{ height: 34, width: 100 }}></div>
      {/* <MyDivider color="#52232C"></MyDivider> */}
      <MyDivider></MyDivider>
      <Typography
        gutterBottom
        variant="h6"
        style={{ fontWeight: "bold", fontSize: 22, paddingBottom: 12 }}
      >
        {t("games")}
      </Typography>
      <Grid
        justifyContent={isMobileOnly ? "center" : "flex-start"}
        container
        spacing={3}
      >
        {games &&
          games.map((game, index) => {
            return (
              <Grid key={index} item>
                <DigitalGameCard
                  mode="sale"
                  productData={game}
                ></DigitalGameCard>
              </Grid>
            );
          })}
      </Grid>
      {/**-------------------------- */}
      <div style={{ height: 34, width: 100 }}></div>
      {/* <MyDivider color="#52232C"></MyDivider> */}
      <MyDivider></MyDivider>
      <Typography
        gutterBottom
        variant="h6"
        style={{ fontWeight: "bold", fontSize: 22, paddingBottom: 12 }}
      >
        {t("OSTs")}
      </Typography>
      <Grid
        justifyContent={isMobileOnly ? "center" : "flex-start"}
        container
        spacing={3}
      >
        {osts &&
          osts.map((ost, index) => {
            return (
              <Grid key={index} item>
                <DigitalGameCard
                  mode="sale"
                  productData={ost}
                ></DigitalGameCard>
                {/* <DigitalOSTCard ost={ost}></DigitalOSTCard> */}
              </Grid>
            );
          })}
      </Grid>
      {/* </Card> */}
    </Container>
  );
}
