// import { attributes, react as HomeContent } from "../content/home.md";
import React, { useEffect } from "react";
// import HomePage from "../src/Home/HomePage";
import { GetStaticProps } from "next";
import HomeBannerCarousel from "../src/Home/HomeBannerCarousel";
import HomePage from "../src/Home/HomePage";
import MetaTags from "../src/SEO/MetaTags";
import { BundleStoryData } from "functions/src/shared";
import {
  fetchStoriesData,
  fetchStoryContent,
  fetchStoryData,
} from "functions/src/shared/Digital/Storyblok/fetchStory";
import { findProductsWithTag } from "functions/src/shared/Digital/Storyblok/RedCandleEshop/queryProducts";
import {
  PageStoryblok,
  PageStoryData,
  ProductStoryData,
} from "functions/src/shared/Digital/StoryBlockSchema/components-schema";
import { PageContainer } from "../src/Basic/PageContainer";
import TranslateWrapper from "../src/i18n/TranslateWrapper";
import { useTheme } from "@mui/material/styles";
import { fetchCommonTranslate } from "../lib/Storyblok/fetchTranslation";
import { useSmDown } from "../src/Nav/useDownSM";
import NoSSR from "../src/Utility/NoSSR";
import { Box, Container } from "@mui/material";
import CenterBox from "../src/Utility/CenterBox";
import Link from "next/link";

//https://netlify-config-builder.marcossi.com/
// import styles from '../styles/Home.module.css'
export default function Home({
  games,
  osts,
  bundles,
  locale,
  pageContent,
}: {
  locale: string;
  games: ProductStoryData[];
  osts: ProductStoryData[];
  bundles: BundleStoryData[];
  pageContent: PageStoryblok;
}) {
  //TODO: put meta in storyblok
  // const zhTitle = "赤燭網路商店";
  // const enTitle = "Red Candle Games E-Shop";
  // const title = locale == "zh-TW" ? zhTitle : enTitle;
  // const zhDescription =
  //   "我們是遊戲開發者，我們是赤燭。目標開發一堆促咪，集結各種巧思、理念和故事而成的作品，敬請期待！";
  // const engDescription =
  //   "Red Candle Games, game developer from Taiwan. We strive to bring you the most immersive gaming experience.";
  // const description = locale == "zh-TW" ? zhDescription : engDescription;
  const theme = useTheme();
  console.log("theme", theme);
  useEffect(() => {
    // console.log("Index Page", pageContent.body[0]);
    // console.log("Games", games);
    // console.log("Bundles", bundles);
    // Client.query(Prismic.Predicates.at("document.type", "products"), {
    //   graphQuery: proudctCardQuery,
    //   lang: langContext.lng,
    // }).then((res) => {
    //   console.log("Results", res.results);
    // });
  }, []);
  const smDown = useSmDown();
  console.log("smDown", smDown);

  const meta = pageContent.meta;
  return (
    <PageContainer style={{ padding: 0, width: "100%", maxWidth: "100%" }}>
      <MetaTags
        relativePath=""
        title={meta.title}
        description={meta.description}
        locale={locale}
        imgURL={meta.og_image}
      ></MetaTags>
      <NoSSR>
        {/* {!smDown && <HomeBannerCarousel></HomeBannerCarousel>} */}
        {/* <Container maxWidth="lg" style={{ padding: 36 }}>
          <CenterBox>
            <img width="100%" src={pageContent.body[0].img.filename + "/m/"} />
            <Link passHref href="/projects/ninesols">
              <a>
                <img width="100%" src={meta.og_image + "/m/"}></img>
              </a>
            </Link>
          </CenterBox>
        </Container> */}
        <TranslateWrapper ns="homepage">
          <HomePage games={games} osts={osts} bundles={bundles}></HomePage>
        </TranslateWrapper>
      </NoSSR>
    </PageContainer>
    // <div className={styles.container}>
    //   <Head>
    //     <title>Home Page</title>
    //     <link rel="icon" href="/favicon.ico" />
    //   </Head>

    //   <main className={styles.main}>
    //     <h1 className={styles.title}>
    //       HomePage <a href="https://nextjs.org">Next.js!</a>
    //     </h1>

    //     <p className={styles.description}>
    //       Get started by editing{' '}
    //       <code className={styles.code}>pages/index.js</code>
    //     </p>

    //     <div className={styles.grid}>
    //       <a href="https://nextjs.org/docs" className={styles.card}>
    //         <h3>Documentation &rarr;</h3>
    //         <p>Find in-depth information about Next.js features and API.</p>
    //       </a>

    //       <a href="https://nextjs.org/learn" className={styles.card}>
    //         <h3>Learn &rarr;</h3>
    //         <p>Learn about Next.js in an interactive course with quizzes!</p>
    //       </a>

    //       <a
    //         href="https://github.com/vercel/next.js/tree/master/examples"
    //         className={styles.card}
    //       >
    //         <h3>Examples &rarr;</h3>
    //         <p>Discover and deploy boilerplate example Next.js projects.</p>
    //       </a>

    //       <a
    //         href="https://vercel.com/import?filter=next.js&utm_source=create-next-app&utm_medium=default-template&utm_campaign=create-next-app"
    //         className={styles.card}
    //       >
    //         <h3>Deploy &rarr;</h3>
    //         <p>
    //           Instantly deploy your Next.js site to a public URL with Vercel.
    //         </p>
    //       </a>
    //     </div>
    //   </main>

    //   <footer className={styles.footer}>
    //     <a
    //       href="https://vercel.com?utm_source=create-next-app&utm_medium=default-template&utm_campaign=create-next-app"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Powered by{' '}
    //       <img src="/vercel.svg" alt="Vercel Logo" className={styles.logo} />
    //     </a>
    //   </footer>
    // </div>
  );
}

export const getStaticProps: GetStaticProps = async ({ params, locale }) => {
  //直接搜尋了
  console.log("index page", locale);
  //TODO: index放列表？ 就不是用搜尋的就比較好
  //撈特定Tag
  const gameProductFetch = findProductsWithTag("game", locale);
  const ostProductFetch = findProductsWithTag("ost", locale); //這個有點蠢？還是其實很棒，
  const bundleFetch = fetchStoriesData<BundleStoryData>({
    locale,
    filter: { fieldName: "component", fieldValue: "Bundle" },
  });

  const pageFetch = fetchStoryData<PageStoryData>({
    locale,
    full_slug: "index",
  });
  // const translateFetch = ;
  const gameProducts = await gameProductFetch;
  const ostProducts = await ostProductFetch;
  const bundles = await bundleFetch;
  const page = await pageFetch;
  // const commonTranslate = await translateFetch;

  return {
    props: {
      locale: locale,
      games: gameProducts,
      osts: ostProducts,
      bundles,
      pageContent: page.content,
      translate: { common: await fetchCommonTranslate(locale) },
    },
  };
};
